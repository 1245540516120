import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { removeCookie, getCookie } from '../assets/js/cookie';

let isShow = false

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 3600000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    if (getCookie() !== '' && getCookie() !== null && getCookie() !== 'null') {
      config.headers['Token'] = getCookie()
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

function showLogout() {
  removeCookie('yw')
  ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    router.replace({ path: '/login' })
  }).catch(() => {})
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message || '接口异常'
    if (code === 401) {
      if (!isShow) {
        showLogout()
        isShow = true
        setTimeout(()=>{
          isShow = false
        }, 1500)
      }
    } else if (code === 500) {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      ElMessage.error(msg)
      return Promise.reject('error')
    } else {
      return Promise.resolve(res.data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
