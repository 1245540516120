<script setup>
  import { ref, computed, reactive, watchEffect } from "vue";
  import { createPayLog, alipayForm, writeType } from '../api/index'
  import { ElMessage, ElLoading } from 'element-plus'
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from 'vuex'
  import { invoice, packageList } from "../api/company";
  import { ssoInfo } from "../api/index"
  import Bus from "../js/Bus";
  const route = useRoute()
  const router = useRouter()
  const store = useStore()
  const formRef = ref(null);
  const formRef1 = ref(null)
  const formLabelWidth = '80px'
  const payType = ref(0)
  const chargeType = ref(0)
  const receiveType = ref(1)
  const invoiceType = ref(0)
  const totalPrice = ref(0)
  const limitPrice = ref(0)
  const degreeList = ref([])
  const point = ref(0)
  const list = reactive({
    data: []
  })
  const form = reactive({
    money: null,
    packageId: 0,
    deductionAmount: null,
    ques: '',
  })
  const createFrom = reactive({
    degree: 0,
    type: 1
  })
  const validatorMoney = (rule, value, callback) => { 
    if (value <= 0) {
      return callback(new Error('金额需大于0'))
    }
    callback()
  };
  const formRules = {
    money: [
      { required: true, message: '金额不能为空', trigger: 'blur' },
      { validator: validatorMoney, trigger: 'blur' }
    ],
    ques: [{ required: true, message: '退款原因不能为空', trigger: 'blur' }],
    companyName: [{ required: true, message: '企业名称不能为空', trigger: 'blur' }],
    creditCode: [{ required: true, message: '企业税号不能为空', trigger: 'blur' }],
    bankName: [{ required: true, message: '开户行不能为空', trigger: 'blur' }],
    bankNumber: [{ required: true, message: '银行账户不能为空', trigger: 'blur' }],
    registerAddress: [{ required: true, message: '企业地址不能为空', trigger: 'blur' }],
    registerPhone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }],
    address: [{ required: true, message: '接收地址不能为空', trigger: 'blur' }],
    mail: [{ required: true, message: '电子邮箱地址不能为空', trigger: 'blur' }],
    receiveName: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
    receivePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
  }
  const payLogId = computed({
    get () {
      return store.state.payLogId
    },
    set (val) {
      store.commit('updatePayLogId', val)
    }
  })
  const form1 = computed({
    get () {
      return store.state.invoice
    },
    set (val) {
      store.commit('updateInvoice', val)
    }
  })
  const show = computed({
    get () {
      return store.state.showAuth
    },
    set (val) {
      store.commit('updateAuth', val)
    }
  })
  // const amount = computed({
  //   get () {
  //     return store.state.amount
  //   },
  //   set (val) {
  //     store.commit('updateAmount', val)
  //   }
  // })
  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })
  const type = computed({
    get () {
      return store.state.type
    },
    set (val) {
      store.commit('authType', val)
    }
  })
  const changeType1 = computed({
    get () {
      return store.state.chargeType
    },
    set (val) {
      store.commit('updateChargeType', val)
    }
  })
  // const props = defineProps({
  //   show: {
  //     type: Boolean,
  //     default: false,
  //     required: true
  //   }
  // });
  // const emit = defineEmits(['sbumit', 'update:show'])
  const goAliPay = () => {
    // this.$router.replace({
    //   path: '/subscribe',
    //   query: {
    //     out_trade_no: res.data.payLog.orderNo,
    //     type: 0
    //   }
    // })
    document.body.removeChild(document.getElementById('pay-box'))
  }
  
  const beforePay = () => {
    const loading = ElLoading.service({
      lock: true,
      text: '订单生成中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    let url = window.location.href
    if (url.includes('&charset')) {
      url = url.split('&charset')[0]
    }
    if (url.includes('?charset')) {
      url = url.split('?charset')[0]
    }
    let params = {
      packageId: form.packageId
    }
    // if (chargeType.value === 1) {
    //   params.packageId = form.packageId
    //   params.deductionAmount = form.deductionAmount
    // }
    createPayLog(params).then(ret => {
      if (ret.data) { 
        if (payType.value === 0) {
          alipayForm({
            payLogId: ret.data.id,
            returnUrl: url
          }).then(res => {
            const div = document.createElement('div');
            div.id = 'pay-box'
            div.innerHTML = res.data.form;
            document.body.appendChild(div);
            document.getElementById('pay-box').children[0].submit();
            loading.close()
            goAliPay()
          })
        }
      } else {
        //余额支付
        getUserInfo()
        ElMessage.success('购买成功')
        loading.close()
      }

    }).catch(() => {
      loading.close()
    })
  }

  const submit = () => {
    //0 企业认证 1认证中 2充值 3退款理由 4退款申请等待 5开票申请 6开盘申请等待
    if (type.value === 0) {
      store.commit('updateAuth', false)
      let baseUrl =  window.location.href.split('#')[0]
      let url = baseUrl + '#/auth?type=' + type.value
      window.open(url)
    } else if (type.value === 2) {
      formRef.value.validate((valid) => {
        // 不通过校验
        if (!valid) return
        store.commit('updateAuth', false)
        beforePay()
      })
    } else if (type.value === 3) {
      store.commit('updateAuth', false)
    } else if (type.value === 5) {
      formRef1.value.validate((valid) => {
        if (!valid) {
          return
        }
        let params = JSON.parse(JSON.stringify(form1.value))
        if (receiveType.value === 0) {
          delete params['mail']
        } else {
          delete params['address']
          delete params['receiveName']
          delete params['receivePhone']
        }
        if (invoiceType.value === 0) {
          delete params['bankName']
          delete params['bankNumber']
          delete params['registerAddress']
          delete params['registerPhone']
        }
        params.receiveType = receiveType.value
        params.invoiceType = invoiceType.value
        params.payLogId = payLogId.value
        invoice(params).then(res => {
          ElMessage.success(res.message)
          Bus.$emit('invoiceDone', 1)
          store.commit('updateAuth', false)
        })
      })
    } else if (type.value === 4 || type.value === 6 || type.value === 1) {
      store.commit('updateAuth', false)
    } else if (type.value === 7) {
      if (userInfo.value.isAgent) {
        let num = 100
        if (userInfo.value.balance * 1 < ((point.value * num) * (userInfo.value.agentLevel.discount * num))/(num * num)) {
          ElMessage.error('积分不足，请充值')
          return
        }
      } else {
        if (userInfo.value.balance * 1 < point.value * 1 ) {
          ElMessage.error('积分不足，请充值')
          return
        }
      }
      setTimeout(() => {
        store.commit('updateAuth', false)
      }, 100);
      localStorage.removeItem('stepIndex')
      localStorage.removeItem('writeId')
      localStorage.removeItem('allFeatures')
      if (route.path === '/new') {
        router.replace({ path: '/new', query: {
          type: createFrom.type,
          id: createFrom.degree
        }})
        Bus.$emit('pageChange', {
          type: createFrom.type,
          id: createFrom.degree
        })
      } else {
        router.push({ path: '/new', query: {
          type: createFrom.type,
          id: createFrom.degree
        }})
      }
    } else if (type.value === 8) {
      store.commit('updateAuth', false)
    }
  }
  const changePackage = item => {
    form.packageId = item.id
    let num = 100
    totalPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
    limitPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
    form.deductionAmount = null
  }
  // const inputChange = (value, type) => {
  //   let result =
  //     ("" + value) // 第一步：转成字符串
  //     .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
  //     .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
  //     .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
  //     .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
  //   if (type === 'deductionAmount') {
  //     let num = 100
  //     if (amount.value * 1 >= totalPrice.value * 1) {
  //       if (result * 1 > totalPrice.value * 1) {
  //         result = Number(totalPrice.value)
  //       }
  //     } else {
  //       if (result * 1 > amount.value * 1) {
  //         result = Number(amount.value)
  //       }
  //     }
  //     limitPrice.value = (totalPrice.value * num - result * num)/num
  //   }
  //   form[type] = result
  // }

  const cancel = () => {
    // emit('update:show', false)
    store.commit('updateAuth', false)
  }
  const beforeClose = (done) => {
    // emit('update:show', false)
    store.commit('updateAuth', false)
    done()
  } 
  const invoiceTypeChange = (val) => {
    if (val * 1 === 1) {
      // receiveType.value = 0
    }
  }
  const getPackage = () => {
    packageList().then(res => {
      list.data = res.data
      if (list.data.length > 0) {
        let item = list.data[0]
        form.packageId = item.id
        let num = 100
        totalPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
        limitPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
      }
    })
  }

  const degreeChange = (value) => {
    point.value = degreeList.value.filter(item => item.id === value)[0].point
  }

  const getWriteType = () => {
    writeType().then(res => {
      if (res.data) {
        degreeList.value = res.data
        createFrom.degree = res.data[0].id
        point.value = res.data[0].point
      }
    })
  }

  const getUserInfo = () => {
    ssoInfo().then(res => {
      if (res) {
        store.commit('updateUserInfo', res.data)
        store.commit('updateAmount', res.data.balance)
      }
    })
  }
  const backToHome = () => {
    store.commit('updateAuth', false)
    router.back()
  }
  const title = ref('')
  watchEffect(() => {
    if (show.value) {
      title.value = '提示'
      if (type.value === 2) {   
        if (formRef.value) {
          formRef.value.clearValidate()
        }
        title.value = '平台充值'
        chargeType.value = changeType1.value
        form.deductionAmount = null
        getPackage()
      }
      if (type.value === 3) {
        title.value = '申请退款'
      }
      if (type.value === 5) {
        if (formRef1.value) {
          formRef1.value.clearValidate()
        }
        title.value = '确定开票信息'
        invoiceType.value = 0
        receiveType.value = 1
      }
      if (type.value === 7) {
        getWriteType()
        title.value = '创建新文档'
        createFrom.type = 0
      }
      if (type.value === 8) {
        title.value = '生成大纲'
      }
    }
  })
</script>

<template>
  <div class="soft-dialog">
    <el-dialog 
      :style="{width: type === 7 ? '675px' : type === 5 ? '667px' : '490px'}"
      v-model="show" 
      :title="title"
      :show-close="false"
      :before-close="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div v-if="type === 0"
           class="auth">
        <el-icon size="56"
                 color="#430064"><WarningFilled /></el-icon>
        <div class="auth-item">当前企业还未认证</div>
        <div class="auth-item">认证后才可以使用本系统功能</div>
      </div>
      <div v-else-if="type === 1"
           class="auth">
        <el-icon size="56"
                 color="#430064"><Clock /></el-icon>
        <div class="auth-item">认证审核中，请耐心等待</div>
      </div>
      <div v-else-if="type === 2"
           class="auth">
        <el-form :model="form"
                 :rules="formRules"
                 ref="formRef">
          <!-- <el-form-item label="充值类型">
              <el-radio-group v-model="chargeType">
                <el-radio :label="0">直接充值</el-radio>
                <el-radio :label="1">购买套餐</el-radio>
              </el-radio-group>
            </el-form-item> -->
          <!-- <div v-if="chargeType === 1"> -->
          <el-form-item label="选择套餐"
                        :label-width="formLabelWidth">
            <div class="package-list">
              <div class="package-item"
                   @click="changePackage(item)"
                   :class="{'package-item-active': form.packageId === item.id}"
                   v-for="item in list.data"
                   :key="item.id">
                <div class="name">{{item.name}}</div>
                <!-- <div class="info">{{item.price}}元{{item.balance}}篇（{{(item.price * 1/(item.balance * 1)).toFixed(2)}}元/篇），有限期{{item.validityDays ? item.validityDays + '天' : '无限制'}}</div> -->
              </div>
            </div>
          </el-form-item>
          <el-form-item label="需支付"
                        :label-width="formLabelWidth">
            <!-- <div>
                  <div class="flex-start">
                    <el-input style="width: 170px;" @input="val => inputChange(val, 'deductionAmount')" v-model.trim="form.deductionAmount" />
                    <span class="max-amount">最多可用{{Number(amount).toFixed(2)}}</span>
                  </div>
                </div> -->
            <div class="need-amount">
              <!-- <div>需支付</div> -->
              <div class="limit-price">{{Number(limitPrice).toFixed(2)}} 元</div>
              <div class="discount"
                   v-if="userInfo.isAgent
                     && totalPrice !== 0
                     && userInfo.agentLevel
                     && userInfo.agentLevel.discount !== 1">（已享受代理商专属{{userInfo.agentLevel.discount * 10}}折优惠）</div>
            </div>
          </el-form-item>
          <!-- </div> -->
          <!-- <el-form-item v-else label="充值金额" prop="money" :label-width="formLabelWidth">
              <el-input @input="val => inputChange(val, 'money')" v-model.trim="form.money" />
            </el-form-item> -->
          <!-- v-if="limitPrice * 1 !== 0 || chargeType === 0" -->
          <el-form-item label="充值方式"
                        :label-width="formLabelWidth">
            <div class="charge-type">
              <div class="type-item"
                   @click="payType = 0"
                   :class="{'type-active': payType === 0}">支付宝</div>
              <!-- <div class="type-item" @click="payType = 1" :class="{'type-active': payType === 1}">微信</div> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="type === 3"
           class="auth tui">
        <el-form :model="form"
                 :rules="formRules"
                 ref="formRef">
          <el-form-item label=" "
                        prop="ques"
                        label-width="0">
            <el-input
              type="textarea"
              :rows="6"
              v-model="form.ques"
              placeholder="请输入退款原因"
            />
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="type === 4"
           class="auth">
        <el-icon size="56"
                 color="#430064"><Clock /></el-icon>
        <div class="auth-item">退款申请审核中，请耐心等待</div>
        <div class="auth-desc">款项将退回至原支付渠道</div>
      </div>
      <div v-else-if="type === 5"
           class="auth kaipiao">
        <el-form :model="form1"
                 :rules="formRules"
                 ref="formRef1"
                 label-width="110px" >
          <el-form-item label="企业名称"
                        prop="companyName">
            <el-input v-model="form1.companyName"
                      disabled
                      placeholder="请填写企业名称" />
          </el-form-item>
          <el-form-item label="企业税号"
                        prop="creditCode">
            <el-input v-model="form1.creditCode"
                      placeholder="请填写企业税号" />
          </el-form-item>
          <el-form-item label="发票类型">
            <el-radio-group v-model="invoiceType"
                            @change="invoiceTypeChange">
              <el-radio :label="0">增值税普通发票</el-radio>
              <el-radio :label="1">增值税专用发票</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="invoiceType === 1">
            <el-form-item label="开户行"
                          prop="bankName">
              <el-input v-model="form1.bankName"
                        placeholder="请填写开户行" />
            </el-form-item>
            <el-form-item label="银行账户"
                          prop="bankNumber">
              <el-input v-model="form1.bankNumber"
                        placeholder="请填写银行账户" />
            </el-form-item>
            <el-form-item label="企业地址"
                          prop="registerAddress">
              <el-input v-model="form1.registerAddress"
                        placeholder="请填写企业地址" />
            </el-form-item>
            <el-form-item label="电话号码"
                          prop="registerPhone">
              <el-input v-model="form1.registerPhone"
                        placeholder="请填写电话号码" />
            </el-form-item>
          </div>
          <!-- <el-form-item label="接收方式">
            <el-radio-group v-model="receiveType">
              <el-radio :label="0">纸质发票</el-radio>
              <el-radio :label="1"
                        :disabled="invoiceType === 1">电子发票</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item v-if="receiveType === 0"
                        label="发票收件地址"
                        prop="address">
            <el-input type="textarea"
                      :rows="6"
                      v-model="form1.address"
                      placeholder="请填写发票接收地址" />
          </el-form-item>
          <el-form-item v-else
                        label="邮箱地址"
                        prop="mail">
            <el-input v-model="form1.mail"
                      placeholder="请填写电子邮箱地址" />
          </el-form-item>
          <el-form-item v-if="receiveType === 0"
                        label="收件人"
                        prop="receiveName">
            <el-input  v-model="form1.receiveName"
                       placeholder="请填写收件人" />
          </el-form-item>
          <el-form-item v-if="receiveType === 0"
                        label="联系电话"
                        prop="receivePhone">
            <el-input v-model="form1.receivePhone"
                      placeholder="请填写联系电话" />
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="type === 6"
           class="auth">
        <el-icon size="56"
                 color="#430064"><Clock /></el-icon>
        <div class="auth-item">发票开具中，请耐心等待</div>
        <div class="auth-desc">发票将发送至您指定地址或邮箱</div>
      </div>
      <div v-else-if="type === 7"
           class="create-file">
        <div class="form-item">
          <el-form-item label="选择学历"
                        prop="type">
            <el-radio-group @change="degreeChange"
                            class="education"
                            v-model="createFrom.degree"
                            size="large">
              <el-radio-button :label="item.id"
                               v-for="item in degreeList"
                               :key="item.id">
                <div class="flex-cloum education-item">
                  <span>{{item.name}}</span>
                  <span class="item-words">正文约{{item.words}}字</span>
                </div>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- <div class="form-item">
          <el-form-item label="选择类别"
                        prop="type">
            <el-radio-group v-model="createFrom.type"
                            size="large">
              <el-radio-button :label="1" >开题报告</el-radio-button>
              <el-radio-button :label="0">学术论文</el-radio-button>
              <el-radio-button :label="2" >课题</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div> -->
        <div class="need-jf">
          <div>需要积分<span>{{Number(point).toFixed(2)}}</span></div>
        </div>
      </div>
      <div v-else-if="type === 8"
           class="create-outline">
        <div class="loader"></div>
        <div class="text-item">大纲生成中，请稍后</div>
        <div class="text-item">您可以<span class="back-span"
                                        @click="backToHome" >返回</span>做其他操作</div>
        <div @click="backToHome"
             class="back-btn">返回首页</div>
      </div>
      <template #footer>
        <span v-if="type === 8"></span>
        <span v-else
              class="dialog-footer">
          <div class="add_submit"
               @click="submit">{{ type >= 1 ? '确定': '认证'}}</div>
          <div v-if="type !== 1 && type !== 4 && type !== 6"
               class="add_cancel"
               @click="cancel">{{ type >= 2 ? '取消': '稍后认证'}}</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less">
.soft-dialog {
  .el-dialog {
    border-radius: 8px
  }
  .el-dialog__header {
    text-align: left;
    margin-right: 0;
    border-bottom: 1px solid #D8D8D8;
  }
  .el-checkbox {
    height: 31px;
  }
  .el-form {
    margin: 0 40px;
    .el-form-item {
      margin-bottom: 24px;
      .max-amount {
        margin-left: 8px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #430064;
      }
      .need-amount {
        // margin-top: 24px;
        text-align: left;
        font-size: 18px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #1A1A1A;
        height: 23px;
        line-height: 23px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .limit-price {
          // margin-left: 32px;
          font-size: 18px;
          font-weight: 700;
          color: #EA0000;
        }
        .discount {
          // margin-left: 32px;
          font-size: 14px;
          font-weight: 700;
          color: #EA0000;
        }
      }
    }
    .package-list {
      display: flex;
      flex-direction: column;
      .package-item {
        // width: 300px;
        height: 43px;
        background: #FFFFFF;
        border-radius: 4px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border: 1px solid #767676;
        cursor: pointer;
        .name {
          font-size: 14px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 700;
          color: #767676;
          margin-right: 8px;
        }
        .info {
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #767676;
        }
      }
      .package-item + .package-item {
        margin-top: 8px;
      }

      .package-item-active {
        border: 1px solid #430064 !important;
        .name {
          color: #430064 !important;
        }
      }
    }
  }
  .el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
    .el-icon {
      margin-bottom: 0 !important;
    }
  }
  .tui {
    .el-form {
      margin: 0 40px;
      .el-form-item {
        margin-bottom: 0px;
        .el-form-item__label:before {
          content: '';
        }
      }
    }
  }
  .kaipiao {
    padding-bottom: 0px !important;
  }
  .auth {
    padding: 22px 0 29px;
    .el-icon {
      margin-bottom: 12px;
    }
    .auth-item {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      line-height: 23px;
    }
    .auth-desc {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #AAAAAA;
      margin-top: 8px;
    }
  }
  .create-file {
    padding: 0 24px 0 44px;
    .form-item {
      margin-bottom: 9px;
      position: relative;
      .el-form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .el-form-item__label {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #1A1A1A;
          margin-bottom: 8px;
        }
      }
      .education {
        // background: red;
        display: flex;
        flex-wrap: wrap;
        .el-radio-button {
          height: 60px !important;
          .el-radio-button__inner {
           height: 60px !important; 
           line-height: normal;
           width: 125px;
          }
        }
        .education-item {
          height: 60px !important;
          align-items: center;
          justify-content: center;
          .item-words {
            font-size: 12px;
            color: #767676;
            margin-top: 5px;
          }
        }
      }
      .el-radio-button {
        .el-radio-button__inner {
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          width: 101px;
          padding: 0 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 40px;
          line-height: 40px;
        }
        .el-radio-button__original-radio:checked+.el-radio-button__inner {
          box-shadow: none;
          background: #FFF;
          color: #430064;
          border-color: #430064;
        }
      }
      .el-radio-button {
        margin-right: 15px;
        margin-bottom: 15px;
        height: 40px 
      }
    }
    .need-jf {
      margin-top: 40px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      text-align: left;
      span {
        color: #EA0000;
        margin-left: 8px;
      }
    }
  }
  .el-dialog__footer {
    padding: 0;
  }
  .dialog-footer {
    padding: 24px 20px 20px;
    border-top: 1px solid #D8D8D8;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #430064;
      border-radius: 50px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #430064;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #430064;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
  .charge-type {
    display: flex;
  }
  .type-item {
    width: 118px;
    height: 53px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .type-item + .type-item {
    margin-left: 13px;
  }
  .type-active {
    border: 1px solid #430064;
  }
  .create-outline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-item {
      margin-top: 10px;
    }
    .back-span {
      color: #430064;
      font-weight: bold;
      cursor: pointer;
    }
    .back-btn {
      margin-top: 15px;
      background: #430064;
      border-radius: 15px;
      color: #FFF;
      width: 100px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    /* HTML: <div class="loader"></div> */
    .loader {
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: 
        radial-gradient(farthest-side,#430064 94%,#0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%,#430064);
      -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
      animation: l13 1s infinite linear;
    }
    @keyframes l13{ 
      100%{transform: rotate(1turn)}
    }
  }
}
</style>