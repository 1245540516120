<template>
  <router-view/>
  <SoftDialog></SoftDialog>
</template>

<script>
import SoftDialog from './components/SoftDialog.vue'

export default {
  name: 'App',
  components: {
    SoftDialog
  },
  mounted () {
    document.documentElement.style.setProperty('--el-color-primary', '#430064')
    document.documentElement.style.setProperty('--el-color-primary-dark-2', '#430064')
    document.documentElement.style.setProperty('--el-color-primary-light-3', '#43006475')
    document.documentElement.style.setProperty('--el-color-primary-light-5', '#43006475')
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  /* background: #F2F2F2; */
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.text-center {
  align-items: center;
}
.text-start {
  align-items: flex-start !important;
}
.text-end {
  align-items: flex-end !important;
}
.flex-cloum {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.el-loading-mask .el-loading-spinner .el-loading-text {
  color: #FFF !important;
}

.el-loading-mask .el-loading-spinner .path {
  stroke: #FFF !important;
}

</style>
