<script setup>
  import { onMounted, ref, watchEffect, reactive, computed } from "vue"
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { useRouter, useRoute } from 'vue-router'
  import { consumption, ssoUpdatePassword, ssoInfo, ssoUpdatePhone  } from '../api/index'
  import { smsGetCode } from '../api/authentication'
  import { removeCookie } from '../assets/js/cookie';
  import { useStore } from 'vuex'
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const menuVisible = ref(false)
  const infoMsg = ref('')
  const isHome = ref(false)
  const activeIndex = ref('/home')
  const upPassVisible = ref(false)
  const upPhoneVisible = ref(false)
  const formLabelWidth = '80px'
  const upPassFormRef = ref(null);
  const upPhoneFormRef = ref(null)
  const showText = ref(true)
  const second = ref(60)
  const btnLoading = ref(false)
  const upPassForm = reactive({
    oldPassword: '',
    newPassword: '',
  })
  const upPhoneForm = reactive({
    password: '',
    telephone: '',
    authCode: ''
  })
  const validatorPwd = (rule, value, callback) => {
    // 检验密码强度
    if (!value.trim().length) {
      return callback(new Error('请输入密码！'))
    }
    if (/\d/.test(value) && /[a-zA-Z]/.test(value)) return callback();
    callback( new Error("设置6位以上字母+数字组合的密码"));
  };
  const validatorPhone = (rule, value, callback) => {
    if (userInfo.value.phone && userInfo.value.phone === value.trim()) {
      return callback(new Error('新手机号不能与原手机号重复'))
    }
    callback()
  }
  const formRules = {
    oldPassword: [
      { required: true, message: '密码不能为空', trigger: 'blur' },
    ],
    newPassword: [
      { required: true, message: '密码不能为空', trigger: 'blur' },
      { min: 6, message: '设置6位以上字母+数字组合的密码', trigger: 'blur' },
      { validator: validatorPwd, triger: 'blur' }
    ],
    password: [
      { required: true, message: '密码不能为空', trigger: 'blur' }
    ],
    telephone: [
      { required: true, message: '手机号不能为空', trigger: 'blur' },
      { validator: validatorPhone, triger: 'blur' }
    ],
    authCode: [
      { required: true, message: '验证码不能为空', trigger: 'blur' }
    ]
  }

  const amount = computed({
    get () {
      return store.state.amount
    },
    set (val) {
      store.commit('updateAmount', val)
    }
  })

  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })

  const select = (index) => {
    updateActiveMenuIndex(index)
  }
  const updateActiveMenuIndex = (index) => {
    activeIndex.value = index
  }

  const handleVisible = (visible) => {
    menuVisible.value = visible
  }

  const logout = () => {
    ElMessageBox.confirm('确定退出登录吗？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeCookie()
      store.commit('updateUserInfo', {})
      router.replace({ path: '/login' })
    }).catch(() => {})
  }

  const getUserInfo1 = () => {
    ssoInfo().then(res => {
      if (res) {
        store.commit('updateUserInfo', res.data)
        store.commit('updateAmount', res.data.balance)
      }
    })
  }

  const getUserInfo = (isCreate) => {
    ssoInfo().then(res => {
      if (res) {
        store.commit('updateUserInfo', res.data)
        store.commit('updateAmount', res.data.balance)
        // 企业认证状态:0->未通过；1->企业认证通过;2->代理人商认证通过；3->企业认证审核中；4->代理人商认证审核中
        if (res.data.authStatus === 0) {
          store.commit('authType', 0)
          store.commit('updateAuth', true)
        } else if (res.data.authStatus === 3) {
          store.commit('authType', 1)
          store.commit('updateAuth', true)
        } else {
          if (isCreate) {
            store.commit('authType', 7)
            store.commit('updateAuth', true)
          }
        }
      }
    })
  }

  const createNew = () => {
    getUserInfo(true)
  }

  const changePassword = () => {
    if (upPassFormRef.value) {
      upPassFormRef.value.resetFields()
    }
    upPassForm.oldPassword = ''
    upPassForm.newPassword = ''
    upPassVisible.value = true
  }

  const doChangePassword = () => {
    upPassFormRef.value.validate((valid) => {
      let msg = '请填写密码！'
      // 不通过校验
      if (!valid) return ElMessage.error(msg)
      // 通过校验
      btnLoading.value = true
      let params = {
        oldPassword: upPassForm.oldPassword,
        newPassword: upPassForm.newPassword,
      }
      ssoUpdatePassword(params).then(() => {
        btnLoading.value = false
        upPassVisible.value = false
        removeCookie()
        store.commit('updateUserInfo', {})
        router.replace({ path: '/login' })
      }).catch(() => {
        btnLoading.value = false
      }) 
    })
  }

  const changePhone = () => {
    if (upPhoneFormRef.value) {
      upPhoneFormRef.value.resetFields()
    }
    upPhoneForm.password = ''
    upPhoneForm.telephone = ''
    upPhoneForm.authCode = ''
    upPhoneVisible.value = true
  }

  const getCode = () => {
    let phone = upPhoneForm.telephone
    if (!phone.trim()) {
      ElMessage.error('请输入手机号')
      upPhoneFormRef.value.validateField('telephone')
      return
    }
    smsGetCode({phone}).then(() => {
      showText.value = false
      let interval = setInterval(() => {
        let times = -- second.value
        second.value = times < 10 ? '0' + times : times
      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        second.value = 60
        showText.value = true
      }, 60000)
    })
  }

  const doChangePhone = () => {
    upPhoneFormRef.value.validate((valid) => {
      // 不通过校验
      if (!valid) return
      // 通过校验
      btnLoading.value = true
      let params = {
        password: upPhoneForm.password,
        telephone: upPhoneForm.telephone,
        authCode: upPhoneForm.authCode
      }
      ssoUpdatePhone(params).then(res => {
        btnLoading.value = false
        upPhoneVisible.value = false
        ElMessage.success(res.message)
        getUserInfo1()
      }).catch(() => {
        btnLoading.value = false
      }) 
    })
  }

  const charge = () => {
    store.commit('authType', 2)
    store.commit('updateChargeType', 0)
    store.commit('updateAuth', true)
  }

  const getData = () => {
    consumption().then(res => {
      infoMsg.value = res.data
      store.commit('updateInfoMsg', infoMsg.value)
    })
  }

  const toAuth = (authStatus) => {
    if (authStatus === 3) {
      store.commit('authType', 1)
      store.commit('updateAuth', true)
    } else {
      let baseUrl =  window.location.href.split('#')[0]
      let url = baseUrl + '#/auth?type=0'
      window.open(url)
      // router.push({ 
      //   path: '/auth', 
      //   query:{
      //     type: 0
      //   } 
      // })
    }
  }

  const balanceDetail = () => {
    router.push({ 
      path: '/balance', 
    })
  }

  onMounted(()=> {
    getData()
    getUserInfo()
  })

  watchEffect(()=> {
    updateActiveMenuIndex(route.path)
  })
 
</script>

<template>
  <div class="layout">
    <el-container>
      <el-aside width="200px">
        <div class="aside-header">
          <div class="aside-icon"><img src="../assets/image/logo.png"
                                       alt=""></div>
          <div class="aside-title">学术助手</div>
        </div>
        <el-menu :default-active="activeIndex" 
                 class="el-menu-vertical-demo"
                 :router="true"
                 background-color="transparent"
                 text-color="#FFFFFF"
                 active-text-color="#430064"
                 @select="select">
          <el-menu-item index="/home">
            <!-- <el-icon><icon-menu /></el-icon> -->
            <template #title>工作台</template>
          </el-menu-item>
          <el-menu-item v-if="userInfo.isAdmin"
                        index="/member">
            <template #title>成员管理</template>
          </el-menu-item>
          <el-menu-item v-if="userInfo.isAdmin"
                        index="/record">
            <template #title>订单记录</template>
          </el-menu-item>
          <el-menu-item v-if="userInfo.isAdmin"
                        index="/company">
            <template #title>企业设置</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="header">
          <div class="header-nav flex-between">
            <div :class="{'home-title' : !isHome}"
                 class="title flex-start">
              <!-- <el-icon style="marginRight: 5px"><House /></el-icon> -->
              <span @click="toAuth(userInfo.authStatus)"
                    v-if="userInfo.authStatus * 1 === 0 || userInfo.authStatus * 1 === 3"
                    style="color: #AAAAAA;cursor: pointer;">
                {{userInfo.companyName + '(未认证)'}}
              </span>
              <span v-else >{{userInfo.companyName}}</span> 
              <span class="balance">积分</span>
              <span class="balance-value">{{amount.toFixed(2)}}</span>
              <span @click="balanceDetail"
                    class="balance2">明细></span>
              <div class="charge"
                   @click="charge">积分充值</div>
            </div>
            <div class="info">
              <div class="info-create"
                   @click="createNew">创建新文档</div>
              <div class="info-icon">
                <img v-if="userInfo && userInfo.icon"
                     :src="userInfo.icon"
                     alt="">
                <el-icon v-else
                         :size="14"
                         color="#FFF"><UserFilled /></el-icon>
              </div>
              <el-dropdown @visible-change="handleVisible">
                <div class="el-dropdown-link flex-end">
                  <div class="account">{{userInfo.username}}</div>
                  <el-icon v-if="menuVisible"
                           :size="18"
                           class="el-icon--right">
                    <arrow-up />
                  </el-icon>
                  <el-icon v-else
                           :size="18"
                           class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>
                    <el-dropdown-item @click="changePhone">{{ userInfo.phone ? '换绑手机': '设置手机号'}}</el-dropdown-item>
                    <el-dropdown-item @click="logout">安全退出</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main id="main">
          <!-- <router-view/> -->
          <router-view v-slot="{ Component, route }">
            <keep-alive :exclude="['new','editor','relEditor','auth', 'balance', 'package', 'outline']">
              <component :is="Component"
                         :key="route.name"></component>
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog v-model="upPassVisible"
               title="修改密码">
      <el-form :model="upPassForm"
               :rules="formRules"
               ref="upPassFormRef">
        <el-form-item label="旧密码"
                      prop="oldPassword"
                      :label-width="formLabelWidth">
          <el-input type="password"
                    show-password
                    placeholder="请输入旧密码"
                    v-model="upPassForm.oldPassword"
                    :disabled="isEdit"
                    autocomplete="new-password" />
        </el-form-item>
        <el-form-item label="新密码"
                      prop="newPassword"
                      :label-width="formLabelWidth">
          <el-input type="password"
                    show-password
                    placeholder="设置6位以上字母+数字组合的密码"
                    v-model="upPassForm.newPassword"
                    autocomplete="new-password" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <div class="add_submit"
               :loading="btnLoading"
               :disabled="btnLoading"
               @click="doChangePassword">
            确定
          </div>
          <div class="add_cancel"
               @click="upPassVisible = false">取消</div>
        </span>
      </template>
    </el-dialog>
    <el-dialog style="width: 490px"
               v-model="upPhoneVisible"
               :title="userInfo.phone ? '修改绑定手机': '设置手机号'">
      <el-form :model="upPhoneForm"
               :rules="formRules"
               ref="upPhoneFormRef">
        <el-form-item v-if="userInfo.phone"
                      label="原手机号"
                      :label-width="formLabelWidth">
          <div>{{userInfo.phone}}</div>
        </el-form-item>
        <el-form-item label="密码"
                      v-if="userInfo.phone"
                      prop="password"
                      :label-width="formLabelWidth">
          <el-input type="password"
                    show-password
                    placeholder="请输入登录密码"
                    v-model="upPhoneForm.password"
                    autocomplete="new-password" />
        </el-form-item>
        <el-form-item v-if="userInfo.phone"
                      label="新手机号"
                      class="phone-code"
                      prop="telephone"
                      :label-width="formLabelWidth">
          <el-input v-model="upPhoneForm.telephone"
                    placeholder="请输入新手机号码"
                    :maxlength="11"
                    type="text"
                    autocomplete="off" />
          <el-button class="code-btn"
                     @click="getCode"
                     link
                     :disabled="!showText">{{ showText ? '获取验证码': second + 's后重新获取' }}</el-button>
        </el-form-item>
        <el-form-item v-else
                      label="手机号"
                      class="phone-code"
                      prop="telephone"
                      :label-width="formLabelWidth">
          <el-input v-model="upPhoneForm.telephone"
                    placeholder="请输入手机号码"
                    :maxlength="11"
                    type="text"
                    autocomplete="off" />
          <el-button class="code-btn"
                     @click="getCode"
                     link
                     :disabled="!showText">{{ showText ? '获取验证码': second + 's后重新获取' }}</el-button>
        </el-form-item>
        <el-form-item label="验证码"
                      prop="authCode"
                      :label-width="formLabelWidth">
          <el-input v-model="upPhoneForm.authCode"
                    placeholder="请输入短信验证码"
                    type="text"
                    autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="add_submit"
                     :loading="btnLoading"
                     :disabled="btnLoading"
                     @click="doChangePhone">
            确定
          </el-button>
          <div class="add_cancel"
               @click="upPhoneVisible = false">取消</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<style scoped lang="less">
.layout {
  .phone-code {
    .code-btn {
      position: absolute;
      right: 16px;
      color: #430064;
    }
  }
  :deep(.el-dialog) {
    width: 400px;
  }
  :deep(.el-dialog__header) {
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #430064;
      border-radius: 50px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #430064;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #430064;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
  .el-header {
    padding: 0;
    height: 73px !important;
    position: fixed;
    left: 200px;
    width: calc(100% - 200px);
    z-index: 99;
  }
  .el-aside {
    // background-image: url("../assets/image/aside-bg.png");
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: top;
    background: #430064;
    position: fixed;
    height: 100vh;
    z-index: 999;
    .aside-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 103px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      color: #FFFFFF;
      .aside-icon {
        width: 17px;
        height: 18px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        img {
          margin: auto;
          width: 17px;
          height: 18px;
        }
      }
    }
  }
  .el-menu {
    border-right: none;
  }
  .el-menu-item {
    width: 200px !important;
    height: 55px !important;
    line-height: 55px !important;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
  }

  .el-menu-item.is-active {
    background-color: #FFFFFF !important;
  }

  .el-menu-item:hover {
    background-color: #FFFFFF !important;
    color: #430064 !important;
  }
  .header {
    .header-nav {
      padding: 0px 40px;
      height: 73px;
      background: #FFFFFF;
      box-shadow: 0px 8px 24px 0px rgba(149,157,165,0.2);
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      :deep(:focus-visible) {
        outline: none !important;
      }
      :deep(.el-dropdown) {
        color: inherit;
      }
      .info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .el-dropdown-link {
          cursor: pointer;
        }
        .account {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          color: #1A1A1A;
          margin: 0 8px 0 18px;
        }
        .info-create {
          width: 133px;
          height: 39px;
          border-radius: 100px;
          opacity: 1;
          border: 1px solid #430064;
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #430064;
          box-sizing: border-box;
          cursor: pointer;
          margin-right: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info-icon {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: #430064;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }
      .home-title {
        // cursor: pointer;
      }
      .title {
        font-weight: bold;
        .balance {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #AAAAAA;
          margin: 0 8px 0 24px;
        }
        .balance-value {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #430064;
        }
        .balance-value1 {
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #430064;
          margin-right: 8px;
        }
        .balance1 {
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          color: #9E9E9E;
          cursor: pointer;
        }
        .balance2 {
          margin-left: 8px;
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          color: #9E9E9E;
          cursor: pointer;
        }
        .charge {
          margin-left: 16px;
          //width: 44px;
          height: 19px;
          line-height: 19px;
          background: #FF8F1F;
          border-radius: 100px;
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          color: #FFFFFF;
          margin-right: 17px;
          padding: 0 15px;
          cursor: pointer;
        }
        .fee-info {
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #AAAAAA;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          span {
            margin-left: 4px;
          }
        }
      }
      .logout {
        cursor: pointer;
      }
    }
  }
  .el-main {
    margin-top: 73px;
    margin-left: 200px;
    padding: 0px 0 0;
    min-height: calc(100vh - 73px);
    overflow: unset;
    background: #FAFAFA
  }
}
.package-body {
  max-height: 800px;
  overflow-y: auto;
  padding-right: 12px;
}
.package-pop {
  padding-bottom: 8px;
  .package-title {
    height: 23px;
    font-size: 12px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    color: #1A1A1A;
    line-height: 23px;
    
  }
  .package-item {
    height: 19px;
    font-size: 12px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    color: #767676;
    line-height: 19px;
    .item-value {
      color: #430064;
      margin-left: 16px;
    }
    .package-detail {
      color: #430064;
      cursor: pointer;
    }
  }
}
.package-pop + .package-pop {
  border-top: 1px solid #D8D8D8;
  padding-top: 8px;
}
</style>