import Layout from '@/layout'
import { getCookie } from '../assets/js/cookie';

let redirectUrl = "";

if (getCookie()) {
  redirectUrl = "/home";
} else {
  redirectUrl = "/login";
}
const routes = [
  {
    path: '/',
    redirect: redirectUrl
  },
  {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login')
  },
  {
    path: '/home',
    component: Layout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/home')
    }]
  },
  {
    path: '/member',
    component: Layout,
    children: [{
      path: '/member',
      name: 'member',
      component: () => import('@/views/member')
    }]
  },
  {
    path: '/record',
    component: Layout,
    children: [{
      path: '/record',
      name: 'record',
      component: () => import('@/views/record')
    }]
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/views/auth')
  },
  {
    path: '/company',
    mate: {keepAlive: true},
    component: Layout,
    children: [{
      path: '/company',
      name: 'company',
      component: () => import('@/views/company')
    }]
  },
  {
      path: '/new',
      component: Layout,
      children: [{
        path: '/new',
        name: 'new',
        component: () => import('@/views/new')
      }]
  },
  {
    path: '/outline',
    component: Layout,
    children: [{
      path: '/outline',
      name: 'outline',
      component: () => import('@/views/outline')
    }]
  },
  {
    path: '/editor',
    component: Layout,
    children: [{
      path: '/editor',
      name: 'editor',
      component: () => import('@/views/editor')
    }]
  },
  {
    path: '/relEditor',
    component: Layout,
    children: [{
      path: '/relEditor',
      name: 'relEditor',
      component: () => import('@/views/relEditor')
    }]
  },
  {
    path: '/balance',
    component: Layout,
    children: [{
      path: '/balance',
      name: 'balance',
      component: () => import('@/views/balance')
    }]
  },
  {
    path: '/package',
    component: Layout,
    children: [{
      path: '/package',
      name: 'package',
      component: () => import('@/views/package')
    }]
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    name: 'NotFound',
    component: () => import('@/views/404')
  }
];

export default routes
