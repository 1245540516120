import request from '../../utils/request'

export function getInvoice(params) {
  return request({
    url: '/invoice/',
    method: 'GET',
    params: params
  })
}

export function invoice(params) {
  return request({
    url: '/invoice/',
    method: 'POST',
    params: params
  })
}

export function packageList(params) {
  return request({
    url: '/package',
    method: 'GET',
    params: params
  })
}

export function upInvoice(params) {
  return request({
    url: '/invoice/',
    method: 'PATCH',
    params: params
  })
}

export function companyDetail(params) {
  return request({
    url: '/company/detail',
    method: 'GET',
    params: params
  })
}

export function packageLog(params) {
  return request({
    url: '/package/log',
    method: 'GET',
    params: params
  })
}
