import request from '../../utils/request'

export function smsGetCode (params) {
  return request({
    url: '/sms/getCode',
    method: 'get',
    params: params
  })
}

export function authApply (data) {
  return request({
    url: '/umsCompany/auth/apply',
    method: 'post',
    data
  })
}

export function companyVerify (data) {
  return request({
    url: '/umsCompany/auth/companyVerify',
    method: 'post',
    data
  })
}

export function queryCompany (data) {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/query',
    method: 'post',
    data
  })
}

export function getPayed () {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/payed',
    method: 'get'
  })
}
